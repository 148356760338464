<template>
  <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasSettingsPanel" aria-labelledby="offcanvasSettingsLabel" style="width: 280px;">

    <div class="offcanvas-header pb-2">
      <span class="offcanvas-title fs-5 fw-semibold" id="offcanvasSettingsLabel">Settings</span>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body pt-1">
      <div class="flex-shrink-0 p-1 bg-white">
        <form>
    
          <div class="form-group">
            <label for="colorSelect">Color</label>
            <select id="colorSelect" class="form-select" @change="$emit('menuColorClick', $event.target.value)">
              <option v-for="(option,index) in color?.options" :key="index" :value="option.value" :selected="option.value===colorDefault" >{{option.label}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="containerSelect">Container</label>
            <select id="containerSelect" class="form-select" @change="$emit('menuContainerClick', $event.target.value)">
              <option v-for="(option,index) in container?.options" :key="index" :value="option.value" :selected="option.value===containerDefault" >{{option.label}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="containerPaddingSelect">Container Padding</label>
            <select id="containerPaddingSelect" class="form-select" @change="$emit('menuContainerPaddingClick', $event.target.value)">
              <option v-for="(option,index) in containerPadding?.options" :key="index" :value="option.value" :selected="option.value===containerPaddingDefault" >{{option.label}}</option>
            </select>
          </div>

          <div class="form-group">
            <label for="ktlangSelect">PNP: Keyword Translation</label>
            <select id="ktlangSelect" class="form-select" @change="$emit('menuPnpChange', $event.target.value)">
              <option v-for="(option,index) in ktlang?.options" :key="index" :value="option.value" :selected="option.value===ktlangDefault" >{{option.label}}</option>
            </select>
          </div>

          <div class="form-group form-inline">
            <label>PNP: Glossary</label>
            <div class="form-check" v-for="(option,index) in glossary?.options" :key="index">
              <input 
                type="radio" 
                :id="option.value" 
                name="glossaryRadio" 
                class="form-check-input" 
                :checked="option.value===glossaryDefault"
                @select="$emit('menuPnpChange', option.value)">
              <label class="form-check-label" :for="option.value">{{option.label}}</label>
            </div>
            
          </div>

          <div class="form-group form-inline">
            <label>PNP: Illustrated Glossary</label>
            <div class="form-check" v-for="(option,index) in sbacGlossaryIllustration?.options" :key="index">
              <input 
                type="radio" 
                :id="option.value" 
                name="glossaryIllustrationRadio" 
                class="form-check-input" 
                :checked="option.value===sbacGlossaryIllustrationDefault"
                @select="$emit('menuPnpChange', option.value)">
              <label class="form-check-label" :for="option.value">{{option.label}}</label>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SettingsPanel',

  props: {
    colorClass: {
      type:String,
      default: 'qti3-player-color-default'
    },
    pnp: {
      type:Object,
      default: function () {
        return {
         textAppearance: {
            colorStyle: 'qti3-player-color-default',
          },
          glossaryOnScreen: true,
          keywordTranslationLanguage: '',
          extSbacGlossaryIllustration: false,
          layoutSingleColumn: false
        }
      }
    },
    containerClass:{
      type:String,
      default: 'qti3-player-container'
    },
    containerPaddingClass: {
      type:String,
      default: 'qti3-player-container-padding-2'
    },

  },

  computed: {
    colorDefault () {
      return (this.colorClass)
    },
    glossaryDefault () {
      return (this.pnp?.glossaryOnScreen ? 'glossary-on' :'glossary-off')
    },
    ktlangDefault () {
        return (this.pnp?.keywordTranslationLanguage !== '' ? `ktlang-${this.pnp?.keywordTranslationLanguage}` : 'ktlang-off')
    },
    sbacGlossaryIllustrationDefault () {
      return (this.pnp?.extSbacGlossaryIllustration ?  'sbacGlossaryIllustration-on': 'sbacGlossaryIllustration-off')
    },
    containerDefault () {
        return (this.containerClass)
    },
    
    containerPaddingDefault () {
      return (this.containerPaddingClass)
    }

  },

  data () {
    const formInput = {default: '', options : []}
    return {
      color: formInput,
      glossary: formInput,
      ktlang: formInput,
      sbacGlossaryIllustration: formInput,
      container: formInput,
      containerPadding: formInput,
    }
  },

  methods: {
    
    initialize () {
      this.color = {
        options : [
          {label:'Default', value:'qti3-player-color-default'},
          {label:'Default Reverse', value: 'qti3-player-color-defaultreverse'},
          {label:'High Contrast', value: 'qti3-player-color-blackwhite'},
          {label:'High Contrast Reverse', value: 'qti3-player-color-whiteblack'},
          {label:'Black on Rose', value: 'qti3-player-color-blackrose'},
          {label:'Rose on Black', value: 'qti3-player-color-roseblack'},
          {label:'Medium Gray on Dark Gray', value: 'qti3-player-color-mgraydgray'},
          {label:'Dark Gray on Medium Gray', value: 'qti3-player-color-dgraymgray'},
          {label:'Yellow on Blue', value: 'qti3-player-color-yellowblue'},
          {label:'Blue on Yellow', value:'qti3-player-color-blueyellow'}
        ]
      }
      
      
      this.glossary = {
        options : [
          {label:'On', value:'glossary-on'},
          {label:'Off', value: 'glossary-off'}
        ]
      }

      this.ktlang = {
        options : [
          {label:'Off', value:'ktlang-off'},
          {label:'Arabic', value:'ktlang-ar'},
          {label:'Burmese', value:'ktlang-my'},
          {label:'Cantonese', value:'ktlang-yue'},
          {label:'English', value:'ktlang-en'},
          {label:'Filipino (Ilokano + Tagalog)', value:'ktlang-tl'},
          {label:'German', value:'ktlang-de'},
          {label:'Hmong', value:'ktlang-hmn'},
          {label:'Japanese', value:'ktlang-ja'},
          {label:'Korean', value:'ktlang-ko'},
          {label:'Mandarin', value:'ktlang-cmn'},
          {label:'Punjabi', value:'ktlang-pa'},
          {label:'Russin', value:'ktlang-ru'},
          {label:'Somali', value:'ktlang-so'},
          {label:'Spanish', value:'ktlang-es'},
          {label:'Ukrainian', value:'ktlang-uk'},
          {label:'Vietnamese', value:'ktlang-vi'}
        ]
      }

      
      this.sbacGlossaryIllustration = {
        options : [
          {label:'On', value:'sbacGlossaryIllustration-on'},
          {label:'Off', value: 'sbacGlossaryIllustration-off'}
        ]
      }

      this.container = {
        options : [
          
          {label:'Full Width', value:'qti3-player-container-fluid'},
          {label:'Responsive', value: 'qti3-player-container'}
        ]
      }

      this.containerPadding = {
        options : [
          
          {label:'Padding 0', value:'qti3-player-container-padding-0'},
          {label:'Padding 1', value:'qti3-player-container-padding-1'},
          {label:'Padding 2', value:'qti3-player-container-padding-2'},
          {label:'Padding 3', value:'qti3-player-container-padding-3'},
          {label:'Padding 4', value:'qti3-player-container-padding-4'},
          {label:'Padding 5', value:'qti3-player-container-padding-5'},
        ]
      }

    },

  },

  mounted () {
    this.initialize()
  }
}
</script>

<style>
.b-example-divider {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

</style>
